
















import { defineComponent } from '@vue/composition-api'

import names from '@/router/routes/names'
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue'
import TextLink from '@/components/atoms/TextLink/TextLink.vue'
import TitleGroup from '@/components/molecules/TitleGroup/TitleGroup.vue'
import Auth0SSOButton from '@/components/molecules/Auth0SSOButton/Auth0SSOButton.vue'

export default defineComponent({
  name: 'LoginForm',
  components: {
    SvgIcon,
    TextLink,
    TitleGroup,
    Auth0SSOButton
  },
  setup() {
    return {
      forgotPasswordRoute: { name: names.forgotPassword }
    }
  }
})
