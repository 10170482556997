import { render, staticRenderFns } from "./Auth0SSOButton.vue?vue&type=template&id=25a8906a&scoped=true&"
import script from "./Auth0SSOButton.vue?vue&type=script&lang=ts&"
export * from "./Auth0SSOButton.vue?vue&type=script&lang=ts&"
import style0 from "./Auth0SSOButton.vue?vue&type=style&index=0&id=25a8906a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a8906a",
  null
  
)

export default component.exports