










import { defineComponent } from '@vue/composition-api'
import LoginForm from '@/components/organisms/LoginForm/LoginForm.vue'

export default defineComponent({
  name: 'Login',
  components: {
    LoginForm
  }
})
