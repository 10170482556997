























import { defineComponent } from '@vue/composition-api'
import { Button, AlertMessage } from '@sennder/plankton'

export default defineComponent({
  components: { Button, AlertMessage },
  data: () => ({
    errorMessage: '',
    redirectAfterLoginRoute: ''
  }),

  methods: {
    async loginWithAuth0() {
      try {
        await (this as any).$auth.loginWithPopup()
        const isAuthenticated = await (this as any).$auth.auth0Client.isAuthenticated()
        if (!isAuthenticated) {
          this.errorMessage = this.$t('auth/auth-login-error-message').toString()
        }
      } catch (e) {
        console.error(e)
      }

      this.$router.replace('/')
    }
  }
})
